import React from "react";
import { Link } from "gatsby";
import { Container } from "./Container";
import { Menu, X } from "react-feather";
import logo from "./logo.svg";

export class Base extends React.Component {
  state = { isScrolled: false, isMenuOpened: false };

  componentDidMount() {
    this.handleScroll();
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = ev => {
    this.setState({
      isScrolled: window.scrollY > 10,
    });
  };

  openMenu = ev => {
    this.setState({
      isMenuOpened: true,
    });
    // Prevent scrolling
    document.body.style.top = `-${window.scrollY}px`;
    document.body.style.position = "fixed";
  };

  closeMenu = ev => {
    this.setState({
      isMenuOpened: false,
    });
    // Reverse scrolling prevention
    const scrollY = document.body.style.top;
    document.body.style.top = "";
    document.body.style.position = "";
    window.scrollTo(0, parseInt(scrollY || "0") * -1);
  };

  render() {
    const { children } = this.props;
    const { isScrolled, isMenuOpened } = this.state;
    const navLink =
      "mx-4 text-action block hover:text-action-700 transition duration-500";
    const mobileNavLink = "mx-4 text-action block hover:text-action-700 py-4";

    const menuLinks = [
      {
        label: "Plateforme",
        to: "/#section-features",
      },
      {
        label: "Réseaux",
        to: "/#section-ets",
      },
      {
        label: "Tarifs",
        to: "/#section-pricing",
      },
      {
        label: "FAQ",
        to: "/#section-faq",
      },
      {
        label: "Actualités",
        to: "/actualites",
      },
    ];

    return (
      <div className="w-full  flex flex-col  min-h-screen justify-between">
        <div className="h-4 bg-gray-100"></div>
        <div className="sticky top-0 z-10">
          <div
            className={`w-full transition duration-500 ${
              isScrolled ? "bg-white shadow" : "bg-gray-100"
            }`}
          >
            <Container>
              <div className="flex">
                <div className="w-full flex items-center font-medium">
                  <Link className="flex-1" to="/">
                    <img
                      className="py-4 m-0"
                      src={logo}
                      alt="Omnidoc, plateforme de télé-expertise"
                    />
                  </Link>
                  <div
                    className="md:hidden h-8 w-8 flex items-center justify-center text-action font-bold rounded hover:bg-action-100 cursor-pointer transition duration-500 "
                    onClick={this.openMenu}
                    onKeyDown={this.openMenu}
                    role="button"
                    tabIndex={0}
                  >
                    <Menu />
                  </div>
                  <nav className="hidden md:flex items-center">
                    {menuLinks.map(item => (
                      <Link key={item.label} className={navLink} to={item.to}>
                        {item.label}
                      </Link>
                    ))}
                    <a
                      className="ml-2 px-4 py-1 text-action border border-action bg-white rounded-full hover:bg-action-100 transition duration-500"
                      href="https://app.omnidoc.fr"
                    >
                      Se&nbsp;connecter
                    </a>
                  </nav>
                </div>
              </div>
            </Container>
          </div>
        </div>
        <div
          className={`md:hidden fixed z-20 inset-0 bg-gray-100 pt-8 flex flex-col duration-500 transition-transform transform ease-in-out ${
            isMenuOpened ? "" : "translate-x-full"
          }`}
          style={{ touchAction: "none" }}
        >
          <div className="fixed right-0 top-0 text-2xl text-action cursor-pointer px-8 py-6">
            <div
              className="h-8 w-8 flex items-center justify-center text-action rounded hover:bg-action-100 transition duration-500 cursor-pointer"
              onClick={this.closeMenu}
              onKeyDown={this.closeMenu}
              role="button"
              tabIndex={0}
            >
              <X />
            </div>
          </div>
          <Link onClick={this.closeMenu} to="/">
            <img
              className="mx-auto mt-8"
              src={logo}
              alt="Omnidoc, plateforme de télé-expertise"
            />
          </Link>

          <nav className="text-xl m-auto items-stretch text-center">
            {menuLinks.map(item => (
              <Link
                key={item.label}
                className={mobileNavLink}
                onClick={this.closeMenu}
                to={item.to}
              >
                {item.label}
              </Link>
            ))}

            <div className={mobileNavLink}>
              <a
                className="block px-4 py-2 text-action border border-action bg-white rounded-full hover:bg-action-100 transition duration-500"
                href="https://app.omnidoc.fr"
              >
                Se&nbsp;connecter
              </a>
            </div>
          </nav>
        </div>

        <div>{children}</div>

        <footer className="bg-gray-200 py-24 flex-1">
          <Container>
            <div className="w-full overflow-hidden">
              <div className="flex flex-col lg:flex-row-reverse text-gray-700 -m-4">
                <div className="lg:w-3/5 md:flex ">
                  <div className="p-4 sm:w-1/3">
                    <div className="font-semibold text-gray-800 mb-4">
                      Confidentialité
                    </div>
                    <ul>
                      <li>
                        <Link
                          className="text-gray-700 "
                          target="_blank"
                          rel="noopener noreferrer"
                          to="/cgu"
                        >
                          CGU
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="text-gray-700 "
                          target="_blank"
                          rel="noopener noreferrer"
                          to="/mentions-legales"
                        >
                          Mentions Légales
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="text-gray-700 "
                          target="_blank"
                          rel="noopener noreferrer"
                          to="/donnees-personnelles"
                        >
                          Données Personnelles
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="p-4 sm:w-1/3">
                    <div className="font-semibold text-gray-800 mb-4">
                      Plus sur Omnidoc
                    </div>
                    <ul>
                      <li>
                        <Link
                          className="text-gray-700 "
                          target="_blank"
                          rel="noopener noreferrer"
                          to="/actualites"
                        >
                          Actualités
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="text-gray-700 "
                          target="_blank"
                          rel="noopener noreferrer"
                          to="/presse"
                        >
                          Presse
                        </Link>
                      </li>
                      <li>
                        <a
                          className="text-gray-700 "
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://twitter.com/omnidoc_app"
                        >
                          Twitter
                        </a>
                        {" · "}
                        <a
                          className="text-gray-700 "
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://www.linkedin.com/company/omnidocapp"
                        >
                          Linkedin
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="p-4 sm:w-1/3">
                    <div className="font-semibold text-gray-800 mb-4">
                      Nous contacter
                    </div>
                    <ul>
                      <li>
                        <a
                          className="text-gray-700 "
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://calendly.com/omnidoc/demo-omnidoc"
                        >
                          Demander une démo
                        </a>
                      </li>
                      <li>
                        <a
                          className="text-gray-700 "
                          target="_blank"
                          rel="noopener noreferrer"
                          href="mailto:contact@omnidoc.fr"
                        >
                          contact@omnidoc.fr
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="lg:w-2/5 m-4">
                  <Link to="/">
                    <img
                      src={logo}
                      className="max-w-full"
                      alt="Omnidoc, plateforme de télé-expertise"
                    />
                  </Link>
                  <p className="mt-4 font-light">
                    Omnidoc est une Société par Actions Simplifiée au capital de
                    1000&nbsp;€ (Siren&nbsp;: 847 506 144 R.C.S. Bobigny).
                  </p>
                  <small className="block text-gray-600 mt-4  font-light">
                    &copy; {new Date().getFullYear()} Omnidoc, tous droits
                    réservés.
                  </small>
                </div>
              </div>
            </div>
          </Container>
        </footer>
      </div>
    );
  }
}
